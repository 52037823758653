import styled from 'styled-components';
import { device } from '../../helpers/breakpoints';
import { MeetingType } from '../../types/meetingType';
import CalendarCard from '../CalendarCard';

type MeetingCardProps = {
  meeting: MeetingType;
  onClick?: () => void;
};

export default function MeetingCard({ meeting, onClick }: MeetingCardProps) {
  return (
    <Wrapper onClick={onClick}>
      <Image src={meeting.header_image_url ?? '/modalBg.jpg'} />
      <Content>
        <Title>{meeting.title}</Title>
        <CalendarCard date={meeting.start_date} />
        <Location>{meeting.address?.city}</Location>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(24% - 50px);
  max-width: 350px;
  border-radius: 10px;
  overflow: hidden;
  min-height: 350px;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0px 4px 12px 0px #818181;
  padding-bottom: 15px;
  align-items: center;
  @media ${device.md} {
    flex-basis: calc(50% - 20px);
  }
`;
const Content = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Image = styled.img`
  object-fit: cover;
  height: 240px;
`;
const Title = styled.div`
  font-family: 'PermianSansTypeface', sans-serif;
  font-size: 18px;
  line-height: 1.38;
  height: 50px;
  margin: 10px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Location = styled.div``;
