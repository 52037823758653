import styled from 'styled-components';

type SuggestionRowProps = {
  title: string;
  imageUrl: string | undefined;
  meetingDate: string;
  onSuggestionRowClick?: () => void;
};

function SuggestionRow({ title, imageUrl, meetingDate, onSuggestionRowClick }: SuggestionRowProps) {
  return (
    <Row onClick={onSuggestionRowClick}>
      <Image src={imageUrl ?? '/modalBg.jpg'} />
      <Column>
        <Title>{title}</Title>
        <DateContainer>{new Date(meetingDate).toLocaleString()}</DateContainer>
      </Column>
    </Row>
  );
}

export default SuggestionRow;

const Row = styled.div`
  display: flex;
  gap: 5px;
  transition: 1s;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 5px 0px #818181;
  }
`;
const Image = styled.img`
  width: 100px;
  border-radius: 10px;
`;
const Column = styled.div`
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Title = styled.div`
  font-weight: 100;
  font-size: 15px;
`;
const DateContainer = styled.div`
  font-size: 12px;
  color: grey;
`;
