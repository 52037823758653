import Markdown from 'react-markdown';
import styled from 'styled-components';
import { device } from '../../helpers/breakpoints';
import { MeetingType } from '../../types/meetingType';

type MeetingRowProps = {
  meeting: MeetingType;
  onClick?: () => void;
};

export default function MeetingRow({ meeting, onClick }: MeetingRowProps) {
  const startDate: Date = new Date(meeting.start_date);

  return (
    <Wrapper onClick={onClick}>
      <Image src={meeting.header_image_url ?? '/modalBg.jpg'} />
      <Content>
        <Title>{meeting.title}</Title>
        <EventDate>
          {startDate.toLocaleDateString()} {startDate.toLocaleTimeString()}
        </EventDate>
        <Location>{meeting.address?.city}</Location>
        <Description>{meeting.description}</Description>
      </Content>
      <Col>
        <Group group_color={meeting.group_color}>
          <p>{meeting.group_name}</p>
        </Group>
        <Button>Zapisz się</Button>
      </Col>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin: 10px 0;
  box-shadow: 0px 4px 12px 0px #818181;
  background-color: #e0e0e0;
  padding: 20px;
  border-radius: 10px;
  height: 200px;
  overflow: hidden;
`;
const Image = styled.img`
  object-fit: cover;
  width: 200px;
  min-width: 200px;
  margin: -20px 0 -20px -20px;
`;
const Content = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
`;
const Title = styled.div`
  font-family: 'PermianSansTypeface', sans-serif;
  font-size: 23px;
  line-height: 1.38;
`;
const EventDate = styled.div`
  p {
    margin: 0;
    padding: 0;
  }
`;
const Description = styled(Markdown)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
`;
const Location = styled.div``;

const Col = styled.div`
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const Group = styled.h2<{ group_color?: string }>`
  font-family: 'PermianSansTypeface', sans-serif;
  font-size: 32px;
  line-height: 1.38;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    display: flex;
    align-items: center;
    color: var(--accent-color);
    font-weight: 700;
    font-size: 24px;
    line-height: 1.08;
    &:before {
      content: '';
      margin-right: 7px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: ${({ group_color }) => (group_color ? group_color : 'var(--accent-color)')};
    }
  }
  @media ${device.sm} {
    font-size: 16px;
    p {
      font-size: 14px;
    }
  }
`;

const Button = styled.button`
  width: 236px;
  height: 62px;
  font-family: PermianSansTypeface;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.08;
  border: none;
  cursor: pointer;
  color: var(--background-color);
  background-color: var(--accent-color);

  @media ${device.sm} {
    width: fit-content;
    padding: 18px 34px;
    margin: auto;
    font-size: 20px;
  }
`;
