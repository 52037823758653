import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/pl';
import { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Modal from 'react-modal';
import styled from 'styled-components';
import Footer from '../components/Footer';
import MeetingCard from '../components/MeetingCard/MeetingCard';
import MeetingRow from '../components/MeetingCard/MeetingRow';
import MeetingModalView from '../components/MeetingModalView';
import MainNav from '../components/Navigation/MainNav';
import Banner from '../components/Sliders/Banner';
import { device } from '../helpers/breakpoints';
import { getMeetingById, loadMeetings, meetingsToEventsMapper } from '../services/meetingsService';
import { IEvent } from '../types/Event';
import { MeetingType } from '../types/meetingType';

const root = document.getElementById('przezR') as HTMLElement;
const browserLanguage = navigator.language;
moment.locale(browserLanguage);
const localizer = momentLocalizer(moment);

const NUMBER_OF_CALENDARS = 3;
const NUMBER_OF_CARDS = 12;

const customStyles = {
  content: {
    zIndex: 1000,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '802px',
    padding: '0px',
    border: 'none',
    borderRadius: '10px',
    maxHeight: '90vh',
  },
  overlay: {
    zIndex: 999,
    backgroundColor: 'rgba(122, 122, 122, 0.75)',
  },
};

function CalendarPage() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<MeetingType | null>(null);
  const [meetings, setMeetings] = useState<MeetingType[] | null>(null);
  const [filteredMeetings, setFilteredMeetings] = useState<MeetingType[]>([]);
  const [view, setView] = useState('calendar');

  useEffect(() => {
    async function loadData() {
      const meetings = await loadMeetings();
      const filteredEvents = meetings?.filter((meeting: MeetingType) => {
        const now = new Date();
        const eventDate = new Date(meeting.start_date);
        return (
          eventDate.getFullYear() > now.getFullYear() || (eventDate.getFullYear() === now.getFullYear() && eventDate.getMonth() >= now.getMonth())
        );
      });
      const meetingsSortedByStartDate =
        filteredEvents?.sort((a, b) => {
          return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
        }) ?? [];
      setMeetings(meetingsSortedByStartDate);
    }
    loadData();
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleSelectEvent = useCallback(
    (event: IEvent) => {
      setSelectedEvent(getMeetingById(meetings, event.id as string));
      openModal();
    },
    [meetings]
  );

  const addMonthsToDate = (months: number) => {
    const currentDate = new Date();
    return new Date(currentDate.setMonth(currentDate.getMonth() + months));
  };

  const eventStyleGetter = (event: IEvent) => {
    return {
      style: {
        backgroundColor: event.group_color || 'var(--accent-color)',
        margin: '5px 0',
        color: 'var(--text-color)',
      },
    };
  };

  const CustomShowMore: React.FC<{ total: number; events: IEvent[] }> = ({ total, events }) => {
    return (
      <MoreContainer>
        {`+${total}`}
        {events.map((event: IEvent) => (
          <EventDot key={event.id} color={event.group_color} />
        ))}
      </MoreContainer>
    );
  };

  const handleCardClick = (meeting: MeetingType) => {
    setSelectedEvent(meeting);
    openModal();
  };

  const messages = {
    showMore: (total: number, events: IEvent[]) => <CustomShowMore total={total} events={events} />,
  } as any;

  const renderSetOfCalendars = () => {
    const calendars = [];
    for (let i = 0; i < NUMBER_OF_CALENDARS; i++) {
      calendars.push(
        <Calendar
          key={i}
          localizer={localizer}
          date={addMonthsToDate(i)}
          onNavigate={() => {}}
          events={meetingsToEventsMapper(meetings || [])}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleSelectEvent}
          startAccessor="start"
          popupOffset={{ x: 0, y: 5 }}
          style={{ height: '70vh', minHeight: '580px' }}
          popup
          messages={messages}
        />
      );
    }
    return calendars;
  };

  const renderCards = () => (
    <CardsWrapper>
      {meetings?.map((meeting, index) =>
        index < NUMBER_OF_CARDS ? (
          <MeetingCard key={meeting.id} meeting={meeting} onClick={() => handleCardClick(meeting)} />
        ) : (
          <MeetingRow key={meeting.id} meeting={meeting} onClick={() => handleCardClick(meeting)} />
        )
      )}
    </CardsWrapper>
  );

  const toggleView = () => {
    setView(view === 'calendar' ? 'list' : 'calendar');
  };

  const handleSearch = (phrase: string) => {
    setView('list');
    const filteredMeetings = meetings?.filter(
      (meeting) =>
        meeting.title.toLowerCase().includes(phrase.toLowerCase()) ||
        meeting.description.toLowerCase().includes(phrase.toLowerCase()) ||
        meeting.group_name.toLowerCase().includes(phrase.toLowerCase())
    );
    setFilteredMeetings(filteredMeetings ?? []);
  };

  return (
    <>
      <MainNav viewToggler={toggleView} onSearch={handleSearch} searchResults={filteredMeetings} onSuggestionRowClick={handleCardClick} />
      <Container>
        <Banner />
        {view === 'calendar' ? renderSetOfCalendars() : renderCards()}
      </Container>
      <Footer />
      <Modal isOpen={modalIsOpen} appElement={root} style={customStyles} onRequestClose={closeModal}>
        {selectedEvent && <MeetingModalView meetingDetails={selectedEvent} onRequestClose={closeModal} />}
      </Modal>
    </>
  );
}

export default CalendarPage;

const Container = styled.div`
  padding: 50px 10vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  @media ${device.md} {
    padding: 50px 5vw;
  }
  @media ${device.sm} {
    padding: 50px 10px;
  }
`;

const EventDot = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ color }) => color || 'var(--accent-color)'};
`;

const MoreContainer = styled.span`
  cursor: 'pointer';
  display: flex;
  gap: 5px;
  align-items: center;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 50px;
`;
