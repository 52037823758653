import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdList } from 'react-icons/io';
import styled from 'styled-components';
import { device } from '../../helpers/breakpoints';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { getUniqueGroupNamesWithIds, getUniqueLocations } from '../../services/meetingsService';
import { MeetingType } from '../../types/meetingType';
import SuggestionRow from '../MeetingCard/SuggestionRow';

type SearchNavProps = {
  viewToggler?: () => void;
  onSearch?: (phrase: string) => void;
  searchResults?: MeetingType[];
  onSuggestionRowClick?: (meeting: MeetingType) => void;
};

function SearchNav({ viewToggler, onSearch, searchResults, onSuggestionRowClick }: SearchNavProps) {
  const { t } = useTranslation('navigation', { keyPrefix: 'searchNav' });
  const [groups, setGroups] = useState<{ id: string; name: string }[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [phrase, setPhrase] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const suggestionsRef = useRef(null);
  useOutsideClick(suggestionsRef, () => setShowSuggestions(false));

  useEffect(() => {
    async function loadData() {
      const groups = await getUniqueGroupNamesWithIds();
      const locations = await getUniqueLocations();
      setGroups(groups);
      setLocations(locations);
    }
    loadData();
  }, []);

  const handleSearch = () => {
    onSearch?.(phrase);
  };

  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setPhrase(e.currentTarget.value);
    if (e.currentTarget.value.length > 2) {
      handleSearch();
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();

      if (showSuggestions) {
        setShowSuggestions(false);
      }
    }
  };

  const ToggleSuggestions = useCallback(() => (phrase.length > 2 ? setShowSuggestions(true) : setShowSuggestions(false)), [phrase]);

  useEffect(() => {
    ToggleSuggestions();
  }, [ToggleSuggestions]);

  return (
    <>
      <SearchBar>
        <Box className="searchbar">
          <ViewSwitcher size={30} onClick={viewToggler} />
          <label>{t('search')}</label>
          <input placeholder={t('searchPlaceholder')} onChange={onInputChange} onKeyDown={handleEnter} onFocus={ToggleSuggestions}></input>
          {showSuggestions && (
            <div ref={suggestionsRef}>
              <SearchSuggestions>
                <h5>Spotkania:</h5>
                {searchResults?.length !== 0 ? (
                  searchResults?.map((result) => (
                    <SuggestionRow
                      key={result.id}
                      title={result.title}
                      imageUrl={result.header_image_url}
                      meetingDate={result.start_date}
                      onSuggestionRowClick={() => onSuggestionRowClick?.(result)}
                    />
                  ))
                ) : (
                  <h2>Brak wyników wyszukiwania</h2>
                )}
              </SearchSuggestions>
            </div>
          )}
        </Box>

        <Box className="group">
          <label>{t('groups')}</label>
          <select>
            <option>{t('allGroups')}</option>
            {groups.map((group) => (
              <option key={group.id}>{group.name}</option>
            ))}
          </select>
        </Box>
        <Box className="location">
          <label>{t('localization')}</label>
          <select>
            <option>{t('allPlaces')}</option>
            {locations.map((location) => (
              <option key={location}>{location}</option>
            ))}
          </select>
        </Box>

        <Button onClick={handleSearch}>{t('buttonLabel')}</Button>
      </SearchBar>
    </>
  );
}

export default SearchNav;

const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: end;
  flex-wrap: wrap;
  @media ${device.lg} {
    justify-content: space-between;
  }
`;
const Button = styled.button`
  font-family: PermianSansTypeface;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.08;
  padding: 18px 65px;
  background-color: var(--accent-color);
  border: none;
  color: white;
  height: 62px;
  cursor: pointer;
  @media ${device.lg} {
    flex-basis: 25%;
  }
  @media ${device.md} {
    padding: 18px 24px;
  }
`;
const Box = styled.div<{ minWidth?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 4px;
  height: 92px;
  font-family: PermianSansTypeface;
  font-size: 18px;
  line-height: 1.44;
  width: fit-content;
  &:first-child {
    flex-grow: 1;
  }
  input,
  select {
    height: 62px;
    border: none;
    padding: 18px 24px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    &:focus {
      outline: none;
    }
  }

  &.group,
  &.location {
    display: none;
  }

  label {
    font-weight: bold;
  }
  @media ${device.lg} {
    input,
    select {
      padding: 18px 12px;
    }
    &.searchbar {
      flex-basis: 100%;
    }
    &.group,
    &.location {
      flex-basis: calc(35% - 16px);
    }
  }
  @media ${device.sm} {
    &.group,
    &.location {
      flex-basis: 100%;
    }
  }
`;

const SearchSuggestions = styled.div`
  width: calc(100% - 40px);
  position: absolute;
  min-height: 100px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 12px 0px #818181;
  top: 100px;
  background-color: white;
  border-radius: 10px;
  z-index: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
  h5 {
    margin: 0 0 10px 0;
    font-weight: bold;
    font-size: 18px;
  }
`;

const ViewSwitcher = styled(IoMdList)`
  position: absolute;
  left: -40px;
  top: 50%;
  cursor: pointer;
`;
