import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MeetingType } from '../../types/meetingType';
import SearchNav from './SearchNav';

type MainNavProps = {
  viewToggler?: () => void;
  onSearch?: (phrase: string) => void;
  searchResults?: MeetingType[];
  onSuggestionRowClick?: (meeting: MeetingType) => void;
};

function MainNav({ viewToggler, onSearch, searchResults, onSuggestionRowClick }: MainNavProps) {
  const { t } = useTranslation('navigation');

  return (
    <Navigation>
      <Links>
        <Link href="/" color={'var(--accent-color)'}>
          przezr.pl
        </Link>
        <Link href="https://app.przezr.pl" target="__blank">{t('login')}</Link>
      </Links>
      <SearchNav viewToggler={viewToggler} onSearch={onSearch} searchResults={searchResults} onSuggestionRowClick={onSuggestionRowClick} />
    </Navigation>
  );
}

export default MainNav;

const Navigation = styled.div`
  padding: 16px 10vw;
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Links = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Link = styled.a`
  font-size: 16px;
  font-weight: bold;
  font-family: PermianSansTypeface;
  color: ${({ color }) => color || 'black'};
  text-decoration: none;
`;
